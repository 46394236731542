<template>
  <base-layout>
    <ChatNavigation></ChatNavigation>
    <ion-searchbar show-cancel-button="focus" @ionChange="filter(search)" :value="search"
                   @ionInput="search = $event.target.value;"></ion-searchbar>
    <ion-content>
      <div class="list-group">
        <template v-for="contact in filteredUsers" :key="contact.id">
          <a class="list-group-item list-group-item-action " aria-current="true" @click="startChat(contact)"
             v-if="contact.id != user.id && !contact.isLetter">
            <div class="d-flex flex-row">
              <div style="max-width:60px" class="d-flex flex-column flex-fill align-items-center m-1">
                <img v-if="contact.avatar_id"
                     :src="'https://fileserver.documedias.systems/file/' + contact.avatar_id + '/face/130/130'"
                     style="width:50px; border-radius:50%">
                <img v-if="!contact.avatar_id" src="@/assets/icon/avatar.png" style="width:50px; border-radius:50%">
              </div>
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column w-100 justify-content-between">
                  <h5 >{{ contact.firstname }} {{ contact.lastname }} </h5>
                </div>
              </div>
            </div>
          </a>
          <div class="web-person-divider" v-if="contact.isLetter" style="width:100vw;padding-left: 20px">
            <h1>{{ contact.letter }}</h1>
          </div>
        </template>
      </div>

    </ion-content>
  </base-layout>
</template>

<script>
import {defineComponent} from "vue";
import {IonSearchbar} from "@ionic/vue";
import {mapGetters, mapMutations, mapActions, mapState} from 'vuex';
import ChatNavigation from "@/components/chat/ChatNavigation";

export default defineComponent({
  name: "ChatUsers",
  components: {ChatNavigation, IonSearchbar},
  data() {
    return {
      search: "",
      filtered: [],
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('chat', ['userlist', 'chatUser', 'currentRoom']),
    filteredUsers: function () {
      let returnList = this.filtered.length ? this.filtered: this.userlist;
      returnList =  returnList.sort( ( a, b) => {
        return a.lastname.localeCompare(b.lastname);
      })
      return this.groupList(returnList, 'lastname');
    }
  },

  methods: {
    ...mapActions('chat', ['getUserList', 'registerUser', 'enterRoom']),
    ...mapMutations('chat', ['setCurrentRoom']),

    async startChat(contact) {
      await this.enterRoom([this.user.id, contact.id]);
      this.$router.push('/app/chats/' + this.currentRoom)
    },
    groupList(list, selector){
      let letters = [];
      let currentCharCode = 'A'.charCodeAt(0) - 100;
      let sortedList = [];

      function addLetter(code) {
        let letter = String.fromCharCode(code);
        sortedList.push({
          isLetter: true,
          letter: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().charCodeAt(0);
          let difference = itemCharCode - currentCharCode;
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });

      return sortedList;
    },

    filter(term) {
      if (term.length > 0) {

        this.filtered = this.userlist.filter(
            user => Object.keys(user).some(k => {
              return (user[k] && typeof user[k] == 'string' ? user[k].toLowerCase().includes(term.toLowerCase()) : '')
            })
        ).sort( ( a, b) => {
          return a.lastname.localeCompare(b.lastname);
        });
      } else {
        this.filtered = [];
      }
    },
  },
  mounted() {

  },
  async created() {
    console.log(this.user)

    if (this.user) {
      await this.registerUser(this.user);
      console.log('reguser', this.chatUser)
      await this.getUserList(this.user)
      console.log('user in chat list', await this.userlist)
    }

  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {
        if (to.name == 'Chat') {
          if (this.user) {
            await this.registerUser(this.user);
            console.log('reguser', this.chatUser)
            await this.getUserList(this.user)
            console.log('user in chat list', await this.userlist)
          }
        }
      }
    }
  }
});
</script>
<style lang="scss">


</style>